/* eslint-disable no-undef */
import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

// Elements
import Blogik, { BlogConsumer, BlogFilter } from 'components/shared/Blogik'

// Components
import Image from 'components/shared/ImageGatsby'
import { Link } from 'gatsby'

// SVG

const StyledImage = styled(Image)`
  @media (max-width: 767px) {
    max-height: 263px;
  }

  @media (min-width: 768px) {
    max-height: 357px;
  }

  @media (min-width: 1200px) {
    min-height: 350px;
    max-height: 350px;
  }

  border-top-left-radius: 35px;
`
const ExcerptWrapper = styled.div`
  bottom: 100px;

  @media (max-width: 767px) {
    bottom: 0;
  }
`

const ExcerptContent = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  padding: 2rem 1.5rem;

  @media (min-width: 992px) {
    min-height: 190px;
  }

  & h2 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size[18]};
    color: #858585;

    @media (min-width: 992px) {
      min-height: 43px;
    }
  }
`

const Excerpt = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size.base};
    color: ${(props) => props.theme.color.text.dark};
    line-height: ${(props) => props.theme.font.size[20]};
  }

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1200px) {
      -webkit-line-clamp: 3;
    }

    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
  }
`

const BlogWrapper = styled.div`
  @media (max-width: 767px) {
    border-bottom: 1px solid ${(props) => props.theme.color.text.secondary};
  }
`

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const Blog: React.FC<BlogProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 my-5">
      <div className="row justify-content-center">
        <Blogik
          settings={{
            id: 'blog',
            limit: Number(fields.limit),
          }}
        >
          <div>
            <BlogFilters />
            <BlogGrid fields={fields} />
          </div>
        </Blogik>
      </div>
    </div>
  </section>
)

const StyledBlogFilter = styled(BlogFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected ? props.theme.font.weight.l : props.theme.font.weight.s};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => (
  <BlogConsumer>
    {({ categories, isSelected }: any) => (
      <div className="container d-flex">
        {categories.map((category: BlogFilterProps) => (
          <StyledBlogFilter
            key={category.node.id}
            id={category.node.id}
            selected={isSelected(category.node.id)}
            className="mr-3 d-none"
          >
            {category.node.name}
          </StyledBlogFilter>
        ))}
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts }: any) => (
      <div className="container">
        <div className="row">
          {posts.map((post: BlogGridPostProps) => (
            <div key={post.node.id} className="mb-4 mb-lg-0 col-md-6">
              <BlogGridPost node={post.node} blogFields={fields} />
            </div>
          ))}
        </div>
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: {
    id: string
    title: string
    uri: string
    blogDetail: {
      excerpt: string
      thumbnail: GatsbyTypes.Maybe<GatsbyTypes.WpMediaItem>
    }
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <BlogWrapper>
    <Link to={node?.uri}>
      <StyledImage image={node?.blogDetail?.thumbnail} loading="lazy" />
    </Link>
    <div className="d-flex justify-content-center">
      <ExcerptWrapper className="row position-relative align-items-center justify-content-between mx-lg-5 mx-0">
        <ExcerptContent className="d-flex flex-column justify-content-start col-12">
          <h2>{node?.title}</h2>
          <Excerpt content={node?.blogDetail?.excerpt} />
        </ExcerptContent>
      </ExcerptWrapper>
    </div>
  </BlogWrapper>
)

export default Blog
